<template>
    <div class="teacher-page-builder builder flex-container">
        <div class="column">
            <teacher-page-card v-if="component" :component="component"/>
        </div>
        <properties-panel :save-component="saveComponent"/>
    </div>
</template>
<script>
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'
import TeacherPageCard from '@/components/BackOffice/TeacherPageBuilder/TeacherPageCard.vue'
export default {
    name: 'TeacherPageBuilder',
    mixins:[BuilderMixin], 
    components:{
        TeacherPageCard
    }, 
    computed:{
        component(){
            return this.$store.state.teacherPageComponent
        },
    },
    created(){
        this.$store.commit('updateSwitchButtonDisplayStatus', false)
        this.$store.commit('updateContentSectionState', true)
        this.$store.dispatch('loadTeacherPageComponent', this.$route.params.teacherPageId)
    },
    methods:{
        saveComponent(){
            this.$store.dispatch('updateTeacherPageComponent', this.component)
        }
    },
}
</script>
<style lang="less" scoped>
.teacher-page-builder{
    margin-top: 104px;
    height: calc(100vh - 104px);
}
</style>

