var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "teacher-page-builder builder flex-container" },
    [
      _c(
        "div",
        { staticClass: "column" },
        [
          _vm.component
            ? _c("teacher-page-card", { attrs: { component: _vm.component } })
            : _vm._e()
        ],
        1
      ),
      _c("properties-panel", { attrs: { "save-component": _vm.saveComponent } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }